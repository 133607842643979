.profile_picture_container {
  width: 96px;
  height: 96px;
  position: relative;
}

.profile_picture_upload {
  position: absolute;
  bottom: -4px;
  right: 4px;
  background: #373750;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}