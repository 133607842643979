.deash_board-cta_container {
  padding: 16px;
}

.deash_board-cta {
  margin-bottom: 24px;
}

.ant-card-body {
  padding: 0 !important;
}



.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  width: 200px;


  text-align: center;
  padding: 5px 0;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 30px;
  left:-158px;
  z-index: 1;

  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Character/Primary .85 */

  color: rgba(0, 0, 0, 0.85);
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px #00000026
}
.tooltip .tooltiptextMobileOnly {
  visibility: hidden;
  min-width: 120px;
  width: 200px;


  text-align: center;
  padding: 5px 0;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 30px;
  left:-158px;
  z-index: 1;

  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Character/Primary .85 */

  color: rgba(0, 0, 0, 0.85);
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px #00000026
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .tooltiptextMobileOnly {
  visibility: visible;
}



@media only screen and (min-width: 500px) {
  .dashboard-sidebar {
    margin: 45px
  }

  .home-quickLinks {
    width: 48px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .home-overview {
   
    margin-top: 16px;
    padding-left: 16px;
    display: flex;
    justify-content: left;
    gap:5px;
  }

  .home-overview-icon {
    height: 28px;
    width: 28px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .overview-card-text {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;
    letter-spacing: 0.01em;

    /* Text */

    color: #10202D;

  }

  .quicklinks-card-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    color: #10202D;
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
  }

  .overview-card-number {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
  }

  .overview-mobile {
    display: none !important;
  }

  .quickLinks-card {
    width: 202px;
    height: 124px;
  }

  .overview-card {
    width: 202px;
    height: 124px;
  }


  .dashboard-content {
    display: flex;
    flex-direction: row;
    padding-top: 94px;

  }

  .quick-link-cards {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-top: 16px;

  }

  .overview-cards {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-top: 16px;

  }

  .dashboard-content {
    padding-left: 30px;
    padding-right: 30px;
    gap: 40px;
  }
}

.overview-card-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

@media only screen and (max-width: 500px) {
  .overview-card-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 8px;
    padding-top: 8px;
  }
  .quickLinks-card {
    width: 160px !important;
    height: 124px !important;
    margin: auto !important;
  }

  .home-quickLinks {
    width: 40px;
    height: 40px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px;
  }

  .home-overview {
    margin-left: 12px;
    margin-top: 10px;
    display: flex;
    justify-content: start;
    gap:5px;
  }

  .home-overview-icon {
    height: 21px;
    width: 21px;
  }

  .overview-card {
    width: 104px;
    height: 88px;

    font-size: small;
  }

  .quicklinks-card-text {
    width: 160px;
    height: 48px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #10202D;
    text-align: center !important;
    padding: 8px;
    border-radius: 0px 0px 11px 11px;
    margin-top: 25px;
    padding-left: 16px;
    padding-top: 6px;
    padding-bottom: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overview-card-text {
    width: 59px;
    height: 36px;
    left: 12px;
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    margin-top: 2px;
    text-align: start !important;
    padding-left: 12px;
  }

  .overview-card-number {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: bold;
  }

  .overview-mobile {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .overview-desktop {
    display: none !important;
  }





  .dashboard-content {
    display: flex;
    flex-direction: column;
    padding-top: 32px;

  }

  .quick-link-cards {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-top: 16px;

  }

  .overview-cards {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-top: 16px;

  }

  .dashboard-content {
    padding-left: 20px;
    padding-right: 20px;
    gap: 32px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    width: 169px;
  
  
    text-align: center;
    padding: 5px 0;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    bottom: 40px;
    left:-85px;
    z-index: 1;
  
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
  
    text-align: center;
    letter-spacing: 0.01em;
  
    /* Character/Primary .85 */
  
    color: rgba(0, 0, 0, 0.85);
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px #00000026
  }
  .tooltip .tooltiptextMobileOnly {
    visibility: hidden;
    min-width: 120px;
    width: 10px;
  
  
    text-align: center;
    padding: 5px 0;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    bottom: 41px;
    left:-98px;
    z-index: 1;
  
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
  
    text-align: center;
    letter-spacing: 0.01em;
  
    /* Character/Primary .85 */
  
    color: rgba(0, 0, 0, 0.85);
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px #00000026
  }
}