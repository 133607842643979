.patient-table{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px;
}
td{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #275070;
}
.overview{
    margin-left: 30px;
    margin-top: 80px;
    margin-right: 30px;
}

.add-patient-btn{
    width: 186px !important;
    height: 36px !important;
    background: #DC4A9A !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
    border-radius: 8px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
}

.total-prescription-text{
    color: #10202D;
    font-family: 'Merriweather Sans' !important;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-top: 8px;
}

.quickLinks{
    height: 25%;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    display: flex;
    justify-content: center;
  }

  .prescription-overview-card{
    margin-left: 0px !important;
    width: 202px;
    height: 124px;
  }
  .prescription-overview-card-container{
    padding-top: 16px;
  }

  @media only screen and (min-width: 500px) {
    .prescription-container{
        overflow-y: scroll;
        height: 80vh;
      }
  }
  

@media only screen and (max-width: 500px) {
    .overview{
        margin-left: 20px;
        margin-top: 50px;
        margin-right: 20px;
        
    }
    .prescription-overview-card{
        margin-left: 0px !important;
        width: 104px !important;
        height: 88px !important;
    }
    .icon{
        height: 21px !important;
        width: 21px !important;
        margin-right: 7.5px;
        line-height: 24px;
    }
    .add-patient-btn{
        width: 175px !important;
    }
    .quickLinks{
        margin-left: 22px;
        margin-right: auto;
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
    .total-prescription-text{
        font-size: 12px !important;
        padding-left: 12px !important;
        text-align: start !important;
    }
}