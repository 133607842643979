.quick-action-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #10202D;
}
.quick-action-buttons{
    margin-top: 24px;
}
.quick-action-button{
    width: 354px;
    height: 48px;
    left: 286px;
    top: 241px;
    border: 1px solid #DC4A9A;
    border-radius: 8px;
    display: flex;
    padding: 10px;
    margin-top: 25px;
    cursor: pointer;
}
.quick-action-button-text{
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #10202D;
    margin-left: 10px;
}
.right-arrow-icon{
    height: 25px;
    width: 10px;
    margin-left: auto;
}
.support-text-area{
    width: 355px;
    height: 160px;
    border: 1px solid #A9B9C6;
    border-radius: 8px;
    padding: 10px;
}
.report-bug-btn{
    width: 101px !important;
    height: 36px !important;
    background: #275070 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
    border-radius: 8px !important;
}
.report-bug-btn-text{
    width: 53px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.report-bug-btn-wrapper{
    margin-left: 256px;
    margin-top: 16px;
}
@media only screen and (max-width: 500px) {
    .quick-action-button{
        width: 100%;
        height: 48px;
        left: 286px;
        top: 241px;
        border: 1px solid #DC4A9A;
        border-radius: 8px;
        display: flex;
        padding: 10px;
        margin-top: 25px;
        cursor: pointer;
    }
    .support-text-area{
        width: 100%;
        height: 160px;
        border: 1px solid #A9B9C6;
        border-radius: 8px;
        padding: 10px;
    }
    .report-bug-btn-wrapper{
        margin-left: 256px;
        margin-top: 16px;
    }
    .report-bug-btn-wrapper{
        margin-left: 0px;
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
    }
}