.header_with_profile-wrapper {
  padding: 12px 16px;
 
}
.header_with_profile-container {
  /* display: flex; */
  justify-content: space-between;
}
.header_with_navbar {
  display: flex;
}
.header_with_profile-name_container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.header_with_profile-name_container-icon {
  margin-left: 8px;
}

@media only screen and (min-width: 500px) {
  .header_with_profile-wrapper {
    padding: 38px 30px;
    padding-top: 0px;
  }
  .header_with_profile-name_container {
    margin-top: 24px;
  }
  .header_with_profile-name_container{
    display: none;
  }
  .uvi-logo{
    display: none;
  }
  .doctor-name{
    display: block;
    
  }
  .profile-complition-mobile-only{
    display: none;
  }
  .header_with_profile-desktopOnly{
    display: block;
  }
  .logo-ham{
    display: none;
  }
  .header_with_navbar {
    display: none;
  }

}
@media only screen and (max-width: 500px) {
  .uvi-logo{
    display: block;
  }
  .doctor-name{
    display: none;
  }
  .profile-complition-mobile-only{
    display: block;
  }
  .header_with_profile-desktopOnly{
    display: none;
  }
  .header_with_profile-name_container {
    margin-top: 10px;
  }
  .logo-ham{
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header_with_profile-wrapper {
    padding: 0px 16px;
   
  }
  .header_with_navbar {
    display: block;
  }

}

