.text_input {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  padding: 12px 16px;
  font-weight: 500;
  color: #373750
}

.text_input:focus {
  outline: none !important;
  border: 1px solid #373750;
}

.text_input-lable{
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.text_input-svg {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.drop_down{
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  padding: 12px 16px;
  font-weight: 500;
  color: #373750;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop_down-icon{
  width: 20px
}

.drop_down-check_icon {
    width: 20px
}

.radio_input{
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px; 
  cursor: pointer; 
}

.radio_active{
  border: 1px solid #DC4A9A;
}

.radio_input-container{
  display: flex;
}

.file_input{
  width: 146px;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_input-file_name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.file-name {
  min-width: 106px;
  justify-content: flex-start;
  margin-left: 12px;
}

@keyframes growDown {
  0% {transform: scaleY(0)}
  80% {transform: scaleY(1.1)}
  100% {transform: scaleY(1)}
}

.drop_down-bottom_sheet{
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.16);
  border-radius: 12px;
  overflow: hidden;
  margin-top: 4px;
  z-index: 100;
  max-height: 116px;
  overflow-y: scroll;
  background-color: #FFFFFF;

  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center

  
}

.drop_down-bottom_sheet-elements{
  width: 100%;
  padding: 12px 16px;
  font-weight: 500;
    
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop_down-bottom_sheet-elements-selected {
  background: #F7F7F8;
}

.text_input-prefix{
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 20px;
  top: 8px;
  left: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 500px) {
.file_input-file_name {
    margin-left: 24px;
  }
  /* .file-name{
    min-width: 120px;
    justify-content: flex-start;
    margin-left: 12px;
  } */
}