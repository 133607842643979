.title {
  font-family: Montserrat;
  font-size: 30px;
}
.heading1 {
  font-family: Montserrat;
  font-size: 26px;
}
.heading2 {
  font-family: Montserrat;
  font-size: 24px;
}
.heading3 {
    font-family: Montserrat;
      font-size: 20px;
}
.heading4 {
  font-family: Montserrat;
    font-size: 16px;
}
.heading5 {
  font-family: Montserrat;
    font-size: 14px;
}
.body1 {
  font-family: Montserrat;
  font-size: 18px;
}
.body2 {
  font-family: Montserrat;
  font-size: 16px;
}
.body3 {
    font-family: Montserrat;
      font-size: 14px;
}
.body4 {
  font-family: Montserrat;
  font-size: 12px;
}
.lable {
  font-family: Montserrat;
  font-size: 8px;
}

@media only screen and (min-width: 500px) {
  .dy-h2{
    font-size: 32px;
  }
}