.successfulReferral-col{
    margin-right: 30px;
}
.overview-cards-container{
    display:flex;
    flex-direction: row;
    gap:25px;
    padding-top: 16px;
}


@media only screen and (max-width: 500px) {
    .successfulReferral-col{
        margin-right: 0;
    }
    .overview-cards-container{
       padding-top: 29px;
    }
}