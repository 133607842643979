.view_prescriptions-container {
  padding: 50px 30px;
}

.view_prescriptions-table_header{
  display: none;
}

.view_prescriptions-table_row{
  border: 1px solid #DADADA;
  padding: 16px;
  border-radius: 12px;
}

.view_prescriptions-table{
  margin-top: 20px;
}

.view_prescriptions-table_row_lable{

}
.view_prescriptions-table_row_element{
  margin-bottom: 10px;
}

.view_prescriptions-table_row_btn{
  width: 100%;
  display: flex;
  justify-content: center;
}

.view_prescriptions-empty{
  margin-top: 0px;
  border: 1px solid #DADADA;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
}

@media only screen and (min-width: 500px) {
  .view_prescriptions-container {
    padding: 50px 30px;
  }

  .view_prescriptions-table_row_element-wrap{
    display: flex;
  }

  .view_prescriptions-table_row_element{
    display: flex;
    width: 33%;
  }
  
  .view_prescriptions-table_row_btn {
    width: 30%;
    align-items: center;
    justify-content:start;
  }

  .view_prescriptions-table_row_lable{
    margin-right: 8px;
  }

}