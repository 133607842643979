@media only screen and (max-width:500px)
{
    .ant-layout-sider{
        display: none;
    }
}
.ant-menu-item{
    color: rgb(39, 80, 112) !important;
}
.ant-layout-sider{
    border-right-style: solid !important;
    border-width: 1px !important;
    border-color: rgb(253, 241, 241) !important;
}
.ant-menu-item-selected {
    background-color:rgba(231, 240, 245, 0.5) !important;
}
.sidebar-profile{
   padding-bottom: 24px;
   border-bottom: 1px solid #F0F0F0;
}
.sidebar-profile-image
{
    height: 48px !important;
    width: 48px !important;
}
.sidebar-profile-text
{
   max-width: 60%;
    height: 48px;
   
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
   
    color: #10202D;
}