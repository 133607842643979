.primary_button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 18px;

  background-color: #DC4A9A;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  cursor: pointer;
}

.primary_button-container_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 18px;

  /* background-color: #373750; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  cursor: pointer;
}

.primary_button-icon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}