.button_with_icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 18px;

  background: #F7F8F8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  cursor: pointer;
}

.button_with_icon-icon_container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button_with_icon-text_container{
  margin-left: 18px;
}