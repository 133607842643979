.deash_board-cta_container {
    padding: 16px 20px;
  }
  
  .deash_board-cta {
    margin-bottom: 24px;
  }
  
  
  
  .margin-bottom-all {
    margin-bottom: 16px;
  }
  
  .margin-bottom-only-mobile {
    margin-bottom: 16px;
  }
  
  .referral-education_wrapper {
    border: 1px solid #DADADA;
    padding: 16px 16px 0px 16px;
    border-radius: 12px;
    position: relative;
  }
  
  .education_padding_bottom {
    padding-bottom: 30px;
  }
  
  .referral-education_delete {
    position: absolute;
    bottom: 10px;
    right: 14px;
    background-color: #fff;
  
    cursor: pointer;
  }
  
  
  .referral-title {
    margin-top: 48px;
    margin-bottom: 14px;
  }
  
  .recommendation-check {
    height: 14px;
    width: 14px;
    border: 1.5px solid #F5735A;
    border-radius: 2px;
    margin-right: 11px;
    padding: 1px;
  }
  
  .recommendation-check-active {
    background-color: #F5735A;
  }
  
  .recommendation-check-container {
    display: flex;
    margin-bottom: 20px;
    /* justify-content: flex-end; */
    align-items: center;
    cursor: pointer;
  }
  
  .recommendation-container {
    margin-top: 18px;
  }
  
  /* NEW DESIGN START FROM HERE */
  .prescription-input-subheading {
    margin: 12px 0px;
  }
  
  .prescription-input-inline-remove {
    margin-top: 8px;
    text-align: end;
  }
  
  @media only screen and (min-width: 500px) {
    .profile-form-container {
      padding: 0px 32px;
     
    }
  
    .prescription-input-container {
     
      width: 357px;
    
     
    }
  
    .prescription-input-container-wrapper {
      display: flex;
      gap: 25px;
    }
  
    .referral-education_wrapper {
      width: fit-content;
    }
  
    .education_padding_bottom {
      padding-bottom: 20px;
    }
  
    .margin-bottom-only-mobile {
      margin-bottom: 0px;
    }
  
    .referral-education_delete {
      bottom: 14px;
    }
  }