.deash_board-cta_container {
  padding: 16px 20px;
}

.deash_board-cta {
  margin-bottom: 24px;
}


.margin-bottom-all {
  margin-bottom: 16px;
}

.margin-bottom-only-mobile {
  margin-bottom: 16px;
}

.presc_form_container-education_wrapper {
  border: 1px solid #DADADA;
  padding: 16px 16px 0px 16px;
  border-radius: 12px;
  position: relative;
}
.presc_form_container-prescription_wrapper{
  border: 1px solid #DADADA;
  padding: 16px 16px 0px 16px;
  border-radius: 12px;
  position: relative;
}
.presc_form_container-lab-test_wrapper{
  border: 1px solid #DADADA;
  padding: 16px 16px 0px 16px;
  border-radius: 12px;
  position: relative;
}

.education_padding_bottom {
  padding-bottom: 30px;
}

.presc_form_container-education_delete {
  position: absolute;
  bottom: 10px;
  right: 14px;
  background-color: #fff;

  cursor: pointer;
}

.presc_form_container-title {
  margin-top: 48px;
  margin-bottom: 14px;
}

.reco-check {
  height: 14px;
  width: 14px;
  border: 1.5px solid #DC4A9A;
  border-radius: 2px;
  margin-right: 11px;
  padding: 1px;
}

.reco-check-active {
  background-color: #DC4A9A;
}

.recommendation-check-container {
  display: flex;
  margin-bottom: 20px;
  /* justify-content: flex-end; */
  align-items: center;
  cursor: pointer;
}

.recommendation-container{
  margin-top: 18px;
}

/* NEW DESIGN START FROM HERE */
.presc-input-subheading{
  margin: 12px 0px;
}

.presc-input-inline-remove{
  margin-top: 8px;
  text-align: end;
}

@media only screen and (min-width: 500px) {
  
  .presc-form-container{
    padding: 0px 32px;
    overflow-y: scroll;
    height: 80vh;
  }
  .presc-medical-container{
    margin-bottom: 16px !important;
  }

  .presc-input-container {
   
   
   width: 357px;
 
    margin-bottom: 16px !important;
  }
  .presc-input-prescription{
    width: 100%;
    max-width: 325px;
  }

 .presc-input-lab-test{
  max-width: 100%;
  width: 325px;
 }

  .presc-input-container-wrapper {
    display: flex;
    gap: 28px;
  }
  
  .presc_form_medical_history{
    display: flex;
   gap:25px;
  }

  .presc_form_container-education_wrapper {
     width: 357px;
  }

  .education_padding_bottom {
    padding-bottom: 20px;
  }
  
  .margin-bottom-only-mobile {
    margin-bottom: 0px;
  }

  .presc_form_container-education_delete{
    bottom: 14px;
  }
  .presc_form_container{
    max-width: 357px;
  }
}
@media only screen and (max-width: 500px) {
  .presc-form-container{
    padding: 0px 20px;
  }
}
