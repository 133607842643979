.completionTag {
  width: 25px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  
  position: absolute;
  top: -14px;
  left: -10px;
}
.wrapper {
  
  display: flex;
  flex-direction: row;
 
  align-items: center;
 

  cursor: pointer;
}
.wrapper-p1 {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  cursor: pointer;
}
.progressbar {
  width: 48px;
  height: 48px;
  margin-bottom: 6px;
}
.profile_completion_comp-lable {
  font-family: Montserrat;
  font-size: 8px;
  color: #525268;
  font-weight: 500;
}
.profile_completion_comp-lable-perc {
  font-family: Montserrat;
  font-size: 8px;
  color: #525268;
  font-weight: 500;
  
}
.profile_completion_comp-img{
  width: 40px;
  height: 40px;
  margin-top: -5px;
  border-radius: 50px;
  object-fit: cover;
}
.profile_completion_comp-default_img{
  width: 26px;
  height: 26px;
}

@media only screen and (min-width: 500px) {
  .progressbar {
    width: 52px;
    height: 52px;
  }
  .wrapper{
    gap:12px;
    padding-left: 26px;
  }
  .wrapper-p1 {
    width: 48px;
    height: 48px;
    /* position: absolute;
    right: 6px; */
  }
  .wrapper-p2 {
    max-width: 161px;
    padding-top: 17px;
    /* position: absolute;
    right: 6px; */
  }

  .wrapper-p2-doc{
max-width: 100px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    letter-spacing: 0.25px;
    
    /* Text */
    
    color: #10202D;
  }
  .profile_completion_comp-lable {
    font-size: 12px;
  }
  .profile_completion_comp-lable-perc {
    font-size: 8px;
    border-radius: 8px;
  }
  .completionTag{
    width: 32px;
    height: 20px;

    top: -22px;
    left: -13px;
  }
  .profile_completion_comp-img {
    width: 48px;
    height: 48px;
  }
  .margin-top{
    margin-top: 8px;
  }
  .profile_completion_comp-default_img {
    width: 55px;
    height: 60px;
  }
  .right-arrow img{

    position: relative;
    top: 7px;
   
  }
}
@media only screen and (max-width: 500px) {
  .wrapper{
   position: relative;
   right: 4vw;
  }
  .wrapper-p2 {
    display: block;
  }
  .right-arrow img{

    position: relative;
    top: 4px;
   
  }
}
