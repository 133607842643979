.edit_profile-container{
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 156, 138, 0.2) 0%, rgba(248, 133, 50, 0.04) 100%, rgba(243, 220, 249, 0.2) 100%);
  display: flex;
  flex-direction: column;
  padding: 47px 20px 30px;
}

.edit_profile-name_container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.edit_profile-name_container-icon {
  margin-left: 8px;
  height: 20px;
  width: 20px;
}

.edit_profile-container-logo{
  display: none;
}

@media only screen and (min-width: 500px) {
  .edit_profile-container {
    padding: 22px 48px 30px;
  }

  .edit_profile-container-logo{
    margin-bottom: 30px;
    display: block;
  }
}