.deash_board-cta_container{
  padding: 16px 20px;
}
.deash_board-cta{
  margin-bottom: 24px;
}

.profile-form-container{
  padding: 0px 20px;
}

.profile-input-container {
  margin-bottom: 24px;
}

.profile_form_container-education_wrapper{
  border: 1px solid #DADADA;
  padding: 16px;
  border-radius: 12px;
  position: relative;
}
.education_padding_bottom {
  padding-bottom: 30px;
}

.profile_form_container-education_delete{
  position: absolute;
  bottom: 8px;
  right: 14px;
  background-color: #fff;

  cursor: pointer;
}

.profile_form_container-title {
  margin-top: 48px;
  margin-bottom: 14px;
}

.whatsapp-check {
  height: 14px;
  width: 14px;
  border: 1.5px solid #F5735A;
  border-radius: 2px;
  margin-left: 11px;
  padding: 1px;
}

.whatsapp-check-active {
  background-color: #F5735A;
}

.whatsapp-check-container {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 500px) {
  .profile-form-container{
    padding: 0px 120px;
    overflow-y: scroll;
    height: 80vh;
  }
  .profile-input-container {
    width: 368px;
    margin-right: 48px;
  }
  .profile-input-container-wrapper{
    display: flex;
  }

  .profile_form_container-education_wrapper{
    display: flex;
    border: none;
  }

  .education_padding_bottom{
    padding-bottom: 20px;
  }
}