.patient-image-name{
    display: flex;
    margin-left: 30px;
    margin-top: 48px;
}
.patient-image{
    height: 64px;
    width: 64px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}
.patient-name{
    height: 28px;
    left: 374px;
    top: 138px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #10202D;
    margin-left: 24px;
    margin-top: 15px;
}
.basic-info{
    margin-top: 48px;
    margin-left: 31px;
}
.basic-info-heading{
    width: 104px;
    height: 28px;
    left: 286px;
    top: 232px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #10202D;
}
.basic-info-table{
    width: 1063px;
    height: 112px;
    margin-top: 24px;
}

.active-plan{
    margin-top: 48px;
    margin-left: 31px;
}
.active-plan-heading{
    width: 104px;
    height: 28px;
    left: 286px;
    top: 232px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #10202D;
}
.active-plan-table{
    width: 743px;
    height: 112px;
    margin-top: 24px;
}