.large_button_with_icon-container {
  height: 80px;
  background-color: rebeccapurple;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 16px;

  cursor: pointer;
}
.large_button_with_icon-icon_container{
  width: 64px;
    height: 64px;
}
.large_button_with_icon-text_container {
  margin-left: 24px;
}