.inline_button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inline_button-icon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}